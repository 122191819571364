<template>
  <CRUDFormContainer
    :persistent="persistent"
    :form-size="formSize"
    @close="onFormClose"
    :active.sync="formActive"
  >
    <template v-slot:[formContainerSlot]>
      <v-card :loading="loading">
        <slot name="form" :fields="fieldsModel" :close="close">
          <v-toolbar short flat>
            <v-btn icon @click="close">
              <v-icon>{{ formSize == "full" ? "mdi-arrow-left" : "mdi-close" }}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ headerText }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!readOnly"
              color="primary"
              @click="save()"
              :disabled="
                (!valid || loading || (modifiedFields.length == 0 && !alwaysPostAll)) && !saving
              "
              :loading="saving"
            >
              {{ actionBtnName }}
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation class="crud-form">
              <v-container fluid>
                <v-row>
                  <slot name="form.content">
                    <template v-for="(item, index) in fieldsModel">
                      <v-col :key="index" :cols="item.cols" v-if="!item.hidden">
                        <slot
                          :name="`form.input.${item.field}`"
                          :item="item"
                          :values="values"
                          :modifiedFields="modifiedFields"
                        >
                          <InputControl
                            :attrId="item.field"
                            :label="item.label"
                            :ref="`input_${item.field}`"
                            :value="values[item.field]"
                            :attrType="item.type"
                            :attrRef="item.lov"
                            :multiValue="item.multiple"
                            :control="item.control"
                            :disabled="item.disabled"
                            :rules="item.validation"
                            :bind="item.bind"
                            :noPrependEmptyVal="item.noPrependEmptyVal"
                            @update:value="onUpdateValue($event, item)"
                          />
                        </slot>
                      </v-col>
                    </template>
                  </slot>
                  <slot name="form.append.inner"></slot>
                </v-row>
              </v-container>
            </v-form>
            <slot name="form.append.outer"></slot>
          </v-card-text>
        </slot>
      </v-card>
    </template>
  </CRUDFormContainer>
</template>

<script>
import InputControl from "@/components/util/InputControl";
import CRUDFormContainer from "@/components/util/CRUD/CRUDFormContainer";

export default {
  components: {
    InputControl,
    CRUDFormContainer
  },
  props: {
    persistent: { default: true },
    actionBtnName: { default: "Save" },
    alwaysPostAll: { default: false },
    formActive: { default: false },
    readOnly: { default: false },
    id: { default: "+" },
    loading: { default: false },
    headerText: { default: "" },
    formSize: { default: "full" },
    fields: {
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      valid: true,
      values: {},
      modifiedFields: [],
      saving: false
    };
  },
  computed: {
    formContainerSlot() {
      return this.formSize == "full" ? "default" : "dialog";
    },
    fieldsModel() {
      let fieldsModel = [];
      fieldsModel = this.fields.map((field) => ({
        field: field.name,
        label: field.text,
        value: this.values?.[field.name],
        control: field.input?.control?.toUpperCase(),
        type: this.readOnly ? "text" : field.input?.type,
        lov: field.input?.lov,
        multiple: field.input?.multiple,
        noPrependEmptyVal: field.input?.noPrependEmptyVal,
        bind: { ...{ readonly: this.readOnly }, ...field.input?.bind },
        disabled:
          field.input?.disabled ||
          (field.input?.if?.add == "disabled" && this.id == "+") ||
          (field.input?.if?.update == "disabled" && this.id != "+"),
        validation: field.input?.validation,
        hidden:
          field.input?.hidden ||
          field.actions ||
          (field.input?.if?.add == "hidden" && this.id == "+") ||
          (field.input?.if?.update == "hidden" && this.id != "+"),
        cols: field.input?.cols ?? 6,
        order: field.input?.order ?? 1000
      }));

      fieldsModel.sort((a, b) => (a.order < b.order ? -1 : 1));

      return fieldsModel;
    }
  },
  methods: {
    onUpdateValue(value, item) {
      this.updateValue(item.field, value);
      item.value = value;
      this.$emit("form:input", {
        values: this.values,
        item: item
      });
      this.$forceUpdate();
    },
    load(values, modifiedFields = []) {
      this.saving = false;
      this.modifiedFields = modifiedFields;
      if (typeof values === "object" && values !== null) {
        for (const field of Object.values(this.fields)) {
          if (!(field.name in values)) {
            //set default value on new form
            if (field.input?.value) {
              values[field.name] = field.input.value;
              this.modifiedFields.push(field.name);
            } else {
              values[field.name] = undefined;
            }
          }
        }
        this.values = values;
        this.$forceUpdate();
      }
    },
    updateValue(key, val) {
      this.values[key] = val;
      if (this.modifiedFields.indexOf(key) == -1) {
        this.modifiedFields.push(key);
      }
    },
    saved() {
      this.saving = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        let toPost = Object.entries(this.values).map((e) => {
          return { f: e[0], v: e[1] };
        });
        if (!this.alwaysPostAll) {
          toPost = toPost.filter((f) => this.modifiedFields.includes(f.f));
        }
        this.$emit("save", toPost);
      } else {
        for (let field of Object.values(this.fieldsModel)) {
          if (
            this.$refs[`input_${field.field}`] &&
            this.$refs[`input_${field.field}`][0].focusIfInvalid()
          ) {
            break;
          }
        }
        this.$store.commit("sendAlert", {
          msg: "Please verify the fields in red",
          color: "error"
        });
      }
    },
    onFormClose() {
      if (this.modifiedFields.length > 0) {
        this.$root
          .$confirm("Unsaved Changes", "Are you sure you want to close this form?", {
            color: "red"
          })
          .then((confirm) => {
            if (confirm) {
              this.close();
            }
          });
      } else {
        this.close();
      }
    },
    close() {
      this.$emit("close", true);
    }
  }
};
</script>
