<template>
  <v-autocomplete
    v-model="selectedSync"
    :items="items"
    multiple
    @change="change"
    @input="input"
    v-bind="$attrs"
  >
    <template v-slot:selection="data">
      <draggable
        :id="data.index"
        :list="selectedSync"
        v-bind="dragOptionsChips"
        :move="move"
        @change="change"
      >
        <v-chip
          draggable
          v-model="selectedSync[data.index]"
          :key="data.item.value"
          @mousedown.stop
          @click.stop
          label
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click:close="remove(data.item.value)"
        >
          <b class="mr-1"> {{ data.index + 1 }}.</b>{{ data.item.text }}
        </v-chip>
      </draggable>
    </template>
  </v-autocomplete>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  props: ["items", "value"],
  data() {
    return {
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1
      },
      selectedSync: []
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.selectedSync = val;
      }
    }
  },
  computed: {
    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true
      };
    }
  },
  methods: {
    move: function(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex
      };
    },
    change: function(value) {
      if (value.removed) {
        // insert
        this.selectedSync.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.selectedSync[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.selectedSync.splice(this.dragged.from, 1);
        // RTL
        else this.selectedSync.splice(this.dragged.from + 1, 1);
        this.$emit("input", this.selectedSync);
      }
    },
    input() {
      this.$emit("input", this.selectedSync);
    },
    remove(value) {
      const index = this.selectedSync.indexOf(value);
      if (index >= 0) {
        this.selectedSync.splice(index, 1);
      }
    }
  }
};
</script>
