<template>
  <div>
    <slot>
      <v-dialog
        scrollable
        :value="active"
        :persistent="persistent"
        :max-width="maxWidth"
        @keydown.esc="closeDialog"
        @input="closeDialog"
      >
        <slot name="dialog"> </slot>
      </v-dialog>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    formSize: {
      default: "full"
    },
    active: {
      default: false
    },
    persistent: {
      default: true
    }
  },
  computed: {
    maxWidth() {
      return this.formSize == "full" ? null : this.formSize;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", true);
    }
  }
};
</script>
