<template>
  <v-container fluid>
    <Breadcrumb :classId="classId" :campaignId="campaignId" />
    <Campaign v-if="campaignId" :campaignId="campaignId" :classId="classId" />
    <v-card :loading="loading" v-else>
      <v-toolbar short flat>
        <v-toolbar-title> LNP </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-autocomplete
          class="ml-2"
          dense
          v-model="search.selected"
          :items="search.lovs"
          :loading="search.searching"
          outlined
          hide-no-data
          hide-selected
          hide-details
          prepend-icon="mdi-magnify"
          label="Campaign Name/ID/LON"
          placeholder="Start searching by typing 2 or more chars"
          @update:search-input="searchCampaign"
          @change="goToCampaign"
          :filter="customFilter"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
              <v-list-item-subtitle>ID: {{ item.value }} / LO: {{ item.LO }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-spacer></v-spacer>
        <v-divider class="mx-4" inset vertical></v-divider>

        <ActionButton
          v-for="action in actions"
          :key="action.name"
          :icon="action.icon"
          :tooltip="action.text"
          :disabled="action.name == 'deleteFolder' && items.length > 0"
          @action-click="onActionClick(action.name)"
        />
      </v-toolbar>
      <v-divider />

      <v-data-table
        class="crud-table lnp-table"
        :headers="headers"
        :items="items"
        @click:row="goToCampaign"
        hide-default-footer
        dense
      >
      </v-data-table>
    </v-card>
    <CamPermissionForm :classId="classId" v-model="permForm" />
    <CRUDForm
      ref="crudForm"
      :alwaysPostAll="true"
      @save="createSubFolder"
      @close="newFolder.active = false"
      :formSize="500"
      headerText="New Sub-folder"
      :formActive="newFolder.active"
    >
      <template v-slot:[`form.content`]="">
        <v-row class="py-5">
          <v-col cols="12">
            <v-text-field
              hide-details="auto"
              dense
              label="Folder name"
              v-model="newFolder.name"
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </CRUDForm>
  </v-container>
</template>

<script>
import axios from "axios";
import ActionButton from "@/components/util/ActionButton";
import Breadcrumb from "@/components/lnp/Breadcrumb";
import Campaign from "@/components/lnp/Campaign";
import CamPermissionForm from "@/components/lnp/Form/CamPermissionForm";
import CRUDForm from "@/components/util/CRUD/CRUDForm";

export default {
  components: {
    ActionButton,
    Breadcrumb,
    Campaign,
    CamPermissionForm,
    CRUDForm
  },
  data() {
    return {
      search: {
        selected: null,
        lovs: [],
        searching: false,
        source: undefined
      },
      newFolder: {
        active: false,
        name: undefined
      },
      permForm: false,
      idData: null,
      loading: false,
      searchVal: "",
      actions: [
        { name: "deleteFolder", text: "Delete Current Folder", icon: "mdi-folder-remove" },
        { name: "newFolder", text: "New Folder", icon: "mdi-folder-plus" },
        { name: "managePerm", text: "Manage Folder Permission", icon: "mdi-folder-key" },
        { name: "newCampaign", text: "New Campaign", icon: "mdi-plus" }
      ],
      items: [],
      headers: [
        {
          text: "ID",
          value: "OBJECT_ID",
          width: "100px"
        },
        {
          text: "Name",
          value: "CAMPAIGN_NAME"
        },
        {
          text: "Status",
          value: "STATUS",
          width: "100px"
        },
        {
          text: "Last Modified",
          value: "LAST_MODIFIED",
          width: "200px"
        }
      ]
    };
  },
  watch: {
    classId: {
      immediate: true,
      handler(v) {
        let lastAccessed = this.$store.state.auth.user.ups_profile.LAST_ACCESSED_CAMPAIGN_FOLDER_ID;
        if (!v && lastAccessed) {
          this.$router.push({ params: { classId: lastAccessed } });
        } else {
          this.fetchCampaignList();
        }
      }
    }
  },
  computed: {
    campaignId() {
      return this.$route.params.campaignId;
    },
    classId() {
      return this.$route.params.classId;
    }
  },
  methods: {
    onActionClick(e) {
      switch (e) {
        case "managePerm":
          this.permForm = true;
          break;
        case "newCampaign":
          this.goToCampaign({ OBJECT_ID: "+" });
          break;
        case "newFolder":
          this.newFolder.active = true;
          break;
        case "deleteFolder":
          this.deleteFolder();
          break;
      }
    },
    deleteFolder() {
      this.$axios
        .delete("/lnp/delete_folder", {
          params: {
            class_id: this.classId
          }
        })
        .then((res) => {
          this.$router.push({
            params: {
              classId: res.data.parent_class_id
            }
          });
          this.$store.commit("sendAlert", {
            msg: "Folder deleted",
            color: "success"
          });
        });
    },
    createSubFolder() {
      this.$axios({
        method: "post",
        url: "/lnp/create_subfolder",
        data: {
          class_id: this.classId,
          folderName: this.newFolder.name
        }
      })
        .then((res) => {
          if (res.data.error) {
            this.$store.commit("sendAlert", {
              msg: res.data.error,
              color: "error"
            });
          } else {
            this.newFolder.active = false;
            this.$router.push({
              params: {
                classId: res.data.class_id
              }
            });
          }
        })
        .finally(() => {
          this.$refs.crudForm.saved();
        });
    },
    searchCampaign(val) {
      if (val === null || val.length < 2) {
        this.search.lovs = [];
        return;
      }
      this.search.source && this.search.source.cancel("Operation canceled due to new request.");
      this.search.source = axios.CancelToken.source();
      this.search.searching = true;
      this.$axios
        .get("/lnp/search_campaign", {
          params: {
            term: val,
            class_id: this.classId
          },
          cancelToken: this.search.source.token
        })
        .then((res) => {
          if (res.data) {
            this.search.lovs = res.data;
          }
        })
        .finally(() => {
          this.search.searching = false;
        });
    },
    customFilter() {
      return true;
    },
    fetchCampaignList() {
      if (this.classId) {
        this.loading = true;
        this.$axios
          .get("/lnp/get_campaign_list", {
            params: {
              class_id: this.classId
            }
          })
          .then((res) => {
            this.loading = false;
            this.items = res.data;
          });
      }
    },
    goToCampaign(e) {
      this.search.selected = null;
      this.$router.push({
        params: {
          classId: this.classId,
          campaignId: typeof e === "object" && e !== null ? e.OBJECT_ID : e,
          tab: "info"
        }
      });
    }
  }
};
</script>

<style lang="scss">
.lnp-table {
  td {
    cursor: pointer;
  }
}

.lnp-menu .v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 35px;
}
.lnp-menu {
  border-right: 0;
}
</style>
